.project-card {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 704px;
    max-width: none;
    min-width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0px;
    border-radius: 32px;
    backdrop-filter: blur(5px);
    color: transparent;
    background: linear-gradient(147deg, #232437 50%, #101120 100%);
    cursor: pointer;
}

.project-card .project-card-background {
    position: relative;
    width: 100%;
    height: 98%;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    transition: all 0.4s cubic-bezier(.32, -.01, .15, .98);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.project-card .project-card-background:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(147deg, #232437 0%, #101120 100%);
    opacity: 0.7;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.project-card:hover .project-card-background {
    height: 74%;
}

.project-card-content {
    position: absolute;
    left: 38px;
    top: 24px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 80%;
    height: auto;
    text-align: left;
    padding-top: 20px;

}

.project-card-title {
    font-family: Mulish, sans-serif;
    font-size: 32px;
    line-height: 1.2em;
    font-weight: 700;
    color: #fff;
    margin: 0;
}

.project-card-text {
    position: absolute;
    left: 38px;
    top: auto;
    right: 0;
    bottom: 20px;
    z-index: -2;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: normal;
}

.project-card-text-title {
    padding-bottom: 7px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-align: left;
    color: #fff;
}

.project-card-text-paragraph {
    padding-bottom: 4px;
    text-align: left;
    white-space: pre-line;

}

.project-card-text-paragraph p {
    margin: 0;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #E5E7EB;
}

.project-card-link {
    text-align: right;
    font-weight: 400;
    color: #fff;
}

.project-card-link .link-arrow {
    position: relative;
    top: 1px;
}

.project-card-link:hover {
    text-decoration: underline;
}

.project-card-link a {
    font-family: Mulish, sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: none;
}

.project-card-badge {
    position: absolute;
    left: 24px;
    bottom: 24px;
    z-index: 10;
    display: flex;
    height: 34px;
    padding: 8px 16px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    text-transform: uppercase;
}

@media screen and (max-width: 991px) {
    .project-card-content {
        padding-top: 0;
    }

    .project-card-title {
        font-size: 26px;
        line-height: 44px;
    }
}

@media screen and (max-width: 767px) {
    .project-card:hover .project-card-background {
        height: 70%;
    }

    .project-card-title {
        font-size: 26px;
        line-height: 38px;
    }

    .project-card-content {
        left: 6%;
        width: 88%;
    }
}

@media screen and (max-width: 479px) {
    .project-card-title {
        font-size: 24px;
        line-height: 36px;
    }
}