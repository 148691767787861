.project-dialog .MuiPaper-root {
    border-radius: 32px;
    width: 60vw;
    height: 100vh;
}

.project-dialog .MuiPaper-root::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: url('https://dist.thecoopnetwork.io/plugins/projects-slider/media/project-sketch.webp'), url('https://dist.thecoopnetwork.io/plugins/projects-slider/media/project-sketch.jpg');
    background-size: cover;
    transform: scaleX(-1);
}

.project-dialog .MuiPaper-root:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    box-shadow: inset 0px -27px 33px 0px rgb(255, 255, 255);
}

.project-dialog-image-container {
    position: relative;
    height: 325px;
    border-radius: 32px;
    overflow: hidden;
    margin: -12px 4px 0 4px;
    border-bottom: 4px solid #ffffff;
    z-index: 0;
}

.project-dialog-image-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(20 23 56 / 35%);
    z-index: 10;
}

.project-dialog-video {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}

.project-dialog-image-container img {
    position: absolute;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);
    border-radius: 35px;
    z-index: 1;
}

.project-dialog-content {
    padding: 20px 6% !important;
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.57);
}

.project-dialog-title {
    font-family: Mulish, sans-serif;
    font-size: 29px;
    line-height: 41px;
    font-weight: 700;
    color: #101120;
    padding: 20px 0px 10px;
    width: 60%;
    margin: 0;
}

.project-dialog-subtitle {
    font-family: Mulish, sans-serif;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    margin: 0;
    padding-top: 18px;
    color: #101120;
}

.project-dialog-text {
    white-space: pre-line;
    color: #101120;
    font-size: 15px;
    line-height: 28px;
}

.project-dialog-close {
    position: absolute !important;
    top: 15px;
    right: 15px;
    z-index: 2;
    min-width: 52px !important;
    width: 52px;
    height: 52px;
}

.project-dialog-close img {
    width: 100%;
}

@media screen and (min-width: 992px) {
    .project-dialog-text {
        width: 70%;
        text-align: left;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .project-dialog-image-container {
        height: 339px;
    }

    .project-dialog-image-container img {
        transform: translateY(-60%);
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .project-dialog-image-container {
        height: 230px;
    }

    .project-dialog-image-container img {
        transform: translateY(-63%);
    }
}

@media screen and (max-width: 991px) {
    .project-dialog .MuiPaper-root {
        border-radius: 25px;
        width: calc(100vw - 24px);
        height: 92vh;
        margin: 0 !important;
    }

    .project-dialog-close {
        filter: brightness(0);
        z-index: 5;
    }

    .project-dialog-content {
        padding: 20px !important;
    }
}

@media screen and (max-width: 767px) {
    .project-dialog-title {
        width: 320px;
        font-size: 24px !important;
        line-height: 36px !important;
    }
}