@font-face {
    font-family: 'Mulish';
    src: url('assets/fonts/Mulish-ExtraLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('assets/fonts/Mulish-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('assets/fonts/Mulish-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('assets/fonts/Mulish-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

html,
body {
    font-family: 'Mulish', sans-serif;
}